/* You can add global styles to this file, and also import other style files */
@import './styles/main';
@import 'base/breakpoints';

.page--title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.text--wrap {
  word-break: break-all;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-items-start {
  align-items: start;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-flex-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

@include mobile {
  .page--title {
    font-size: 21px;
  }
}

.row--active {
  background-color: #e7f0f8;
}

.divider {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.banner__link {
  color: var(--cds-secondary-color);
  text-decoration: underline;
}

.text--black {
  color: var(--cds-primary-color);
}

.text--warning {
  color: var(--cds-warning-color);
}

.text--right {
  text-align: right;
}

// TODO: Mover esto al cds pero con un mejor control de z-index
#_CDS_LOADING_PORTAL_ID,
#_CDS_ALERT_PORTAL_ID {
  position: sticky;
  z-index: 99999;
}

.sortable {
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    border-style: solid;
    border-width: 1px 1px 0 0;
    display: inline-block;
    height: 4px;
    width: 4px;
    left: 3px;
    position: absolute;
    vertical-align: top;
  }

  &::before {
    top: 15px;
    transform: rotate(-45deg);
  }

  &::after {
    top: 20px;
    transform: rotate(135deg);
  }

  &.asc {
    &::before {
      border-width: 1.5px 1.5px 0 0;
      border-color: #27282b;
    }
  }

  &.desc {
    &::after {
      border-width: 1.5px 1.5px 0 0;
      border-color: #27282b;
    }
  }
}

.modal__header--full-title {
  .header__title {
    white-space: inherit;
  }
}

.info__window__sub__title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 16px;
}

.info__window__container {
  min-width: 250px;

  .info__window__info {
    display: grid;
    gap: 10px;
    grid-template-columns: 50% calc(50% - 10px);
    margin-bottom: 10px;
  }

  .info__window__info__strong {
    font-weight: 600;
  }

  .info__window__title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}
